/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/scss/style.scss'
require("prismjs/themes/prism-twilight.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")
